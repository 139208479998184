import axios from "axios";
export default (ruta = "") => {

  let rutaApi = ""
  if (ruta == "1") {
    // rutaApi = "https://convocatorias-api.herokuapp.com/api/"
  } else {
    //  rutaApi = "http://localhost/PacificoProject/back_plan_pacifico/public/api/"  //Guillermo local

    rutaApi = "https://rpsostenible.cloudsenactpi.net/api/"

  }

  return axios.create({
    baseURL: rutaApi,    
  });
};
