<template>
  <div>
    <!-- <h3>Bar Chart Example in Vue</h3> -->
    <div class="card card-body">
        <canvas></canvas>
    </div>
  </div>
</template>

<script >
import Chart from "chart.js";

export default {
  name: "indexWater",
  data() {
    return {
      canvas: document.getElementsByTagName("canvas"),
    };
  },
  mounted() {
    this.graficas();
  },

  methods: {
    async graficas() {
      new Chart(this.canvas, {
        type: "line",
        data: {
          labels: [
            "Resultado1",
            "Resultado2",
            "Resultado3",
            "Resultado4",
            "Resultado5",
            "Resultado6",
            "Resultado7",
          ],
          datasets: [
            {
              label: "Total Convocatorias por fase",
              data: [1500, 1300, 1300, 400, 2300, 3300, 400],
              label: "Data One",
              borderColor: "#4f92ff",
              pointBackgroundColor: "#4f92ff",
              borderWidth: 1,
              pointBorderColor: "white",
              backgroundColor: this.gradient,
              borderColor: [
                "rgb(255, 99, 132)",
                "rgb(255, 159, 64)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
                "rgb(54, 162, 235)",
                "rgb(153, 102, 255)",
                "rgb(201, 203, 207)",
              ],
              borderWidth: 1,
            },
          ],
        },
      });
    },
  },
};
</script>

<style >
