<template>
  <div>
    <div class="iconNavbarSidenav" id="iconNavbarSidenav">
      <!-- eslint-disable -->
      <span class="espann">&#9776</span>
    </div>

    <aside
      class="
        sidenav
        navbar navbar-vertical navbar-expand-xs navbar-collapse
        border-0
        fixed-start
        fndcolor
      "
      id="side"
    >
      <div class="sidenav-header text-center">
        <div>
          <img
            class="my-2"
            style="width: 90px; height: 90px; object-fit: cover"
            src="../assets/logoApp 2.png"
            alt=""
          />
        </div>
        <a class="my-3">
          <span class="text-white h6 p-2">Construyendo Pacifico</span>
        </a>
      </div>
      <div
        class="horizontal light mt-0 mb-2 my-3"
        style="height: 2px; background-color: #00aaff"
      />

      <div class="w-auto max-height-vh-100">
        <ul class="navbar-nav my-4">
          <li>
            <a
              class="nav-link text-white nav-itemm"
              @click="
                (reportsManage = true),
                  (userRegister = false),
                  (usersManage = false),
                  (usuariosClick = false),
                  (reportesClick = !reportesClick)
              "
            >
              <div
                class="
                  text-white text-center
                  me-2
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <fa icon="chart-line" />
              </div>
              <span class="pointer nav-link-text">Reportes</span>
            </a>
            <div v-if="reportesClick"></div>
          </li>

          <li>
            <a
              class="pointer nav-link text-white nav-itemm"
              @click="usuariosClick = !usuariosClick"
            >
              <span class="nav-link-text nav-itemm">Gestionar Usuarios</span>
            </a>
            <div v-if="usuariosClick">
              <ul class="navbar-nav ms-2">
                <li>
                  <a
                    class="pointer nav-link text-white nav-itemm"
                    @click="
                      (userRegister = true),
                        (usersManage = false),
                        (reportsManage = false)
                    "
                  >
                    <div
                      class="
                        text-white text-center
                        me-2
                        align-items-center
                        justify-content-center
                      "
                    >
                      <fa icon="add" />
                    </div>
                    <span class="nav-link-text">Registrar Usuario</span>
                  </a>
                </li>
                <li>
                  <a
                    class="pointer nav-link text-white nav-itemm"
                    @click="
                      (usersManage = true),
                        ((userRegister = false), (reportsManage = false))
                    "
                  >
                    <div
                      class="
                        text-white text-center
                        me-2
                        d-flex
                        align-items-center
                        justify-content-center
                      "
                    >
                      <fa icon="users" />
                    </div>
                    <span class="nav-link-text">Usuarios</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li class="my-8">
            <div class="nav-link text-white nav-itemm-close">
              <router-link to="/" class="nav-link text-body px-0">
                <span class="d-sm-inline text-white">Cerrar Sesión</span>
                <fa
                  icon="sign-out-alt"
                  class="ms-2 text-white lead moreSizeIcon"
                />
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </aside>
    <!-- <main
      class="
        main-content
        position-relative
        max-height-vh-100
        h-100
        border-radius-lg
      "
    > -->
      <div>
        <div id="contenido" class="conetnt">
          <div v-if="usersManage">
            <users class="mx-0" />
          </div>

          <div v-if="userRegister">
            <register-userrr class="mx-0" />
          </div>

          <div v-if="reportsManage">
            <reports class="mx-0" />
          </div>
        </div>
      </div>
    <!-- </main> -->
  </div>
</template>
<script>
import registerUserrr from "@/components/manage_users/registerUser.vue";
import users from "@/components/manage_users/index.vue";
import reports from "@/components/manage_reports/index.vue";

export default {
  components: {
    users,
    registerUserrr,
    reports,
  },
  data() {
    return {
      usuariosClick: false,
      reportesClick: false,
      userRegister: false,
      usersManage: false,
      reportsManage: false,
    };
  },

  mounted() {
    this.showSidebar();
  },
  methods: {
    showSidebar() {      

      var btnToggle = document.querySelector("#iconNavbarSidenav");
      btnToggle.addEventListener("click", function () {
        document.getElementById("side").classList.toggle("media");
        document.getElementById("iconNavbarSidenav").classList.toggle("iconNavbarSidenav1");
        document.getElementById("contenido").classList.toggle("conetnt1");
      });
    },

    // showSidebar() {
    //   var side = document.getElementById("iconNavbarSidenav");

    //   side.addEventListener("click", (e) => {
    //     do {
    //       var aside = document.getElementById("side");
    //       aside.classList.remove("navbar-vertical");
    //       this.sidebar = 2;
    //       if (this.sidebar == 2) {
    //         let side = document.getElementById("iconNavbarSidenav");
    //         side.addEventListener("click", (e) => {
    //           var aside = document.getElementById("side");
    //           aside.classList.add("navbar-vertical");
    //           this.sidebar = 1;
    //         });
    //       }
    //     } while (this.sidebar == 1);
    //   });
    // },
  },
};
</script>

<style>
:root {
  --color-1: #00324d;
  --color-2: #00aaff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.nav-itemm:hover {
  background-color: #00aaff;
  border-radius: 8px;
}
.nav-itemm-close:hover {
  background-color: red;
  border-radius: 8px;
}
.media {
  left: -300px !important;
}
.media2 {
  /* left: 0px !important; */
  background-color: #00aaff !important;
}
.fndcolor {
  background-color: #00324d;
}
.containerme {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.color {
  background-color: #00aaff;
}
.iconNavbarSidenav1{
  margin-left: 10px !important;
  
}
.iconNavbarSidenav {
  position: static;
  margin-left: 280px;
  cursor: pointer;
  transition: all 150ms;
}
.conetnt{
    margin-left: 280px !important;
}

.conetnt1{
    margin-left: 0px !important;
/* : 0px !important; */
}

/* @media (max-width: 1201px) {
  .conetnt {
    margin-left: 280px !important;
    transition: all 150ms;
  }
  .media2 {
    left: -300px !important;
    background-color: #00aaff !important;
  }
} */
.espann{
  font-size: 28px;
  color: #00324d;
}
</style>