






import navBase from "@/components/navBase.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    navBase,
  },
})
export default class dashboard extends Vue {
   
}
