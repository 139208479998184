





























































































































































































































import Axios from "@/Axios";
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import loading from "@/components/animations/loading.vue";
import modifyUser from "@/components/manage_users/modifyUser.vue";

import Swal from "sweetalert2";

@Component({
  components: {
    loading,
    modifyUser,
  },
})
export default class indexUsers extends Vue {
  list_data_users = {
    id_user: "",
    usernames: "",
    user_last_names: "",
    identification: "",
    phone_number: "",
    email: "",
    profile_id: "",
    user_nick: "",
  };
  listUserCompo = true;
  idUserProp = "";
  modifyUserCompo = false;
  loadingCompo = false;

  mounted() {
    //
    this.getUsers();
  }
  getUsers() {
    this.loadingCompo = true;
    Axios()
      .get("listar_usuarios")
      .then((response) => {
        if (response.data.status == "success") {
          this.loadingCompo = false;
          this.list_data_users = response.data.results;
        } else {
          this.loadingCompo = false;
          Swal.fire({
            title: "Error",
            icon: "error",
            text: response.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#238276",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "ERROR!",
          icon: "error",
          text: "Se ha presentado un error en el servidor!",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#FF0000",
        });
        this.loadingCompo = false;
      })
      .finally(() => {
        this.loadingCompo = false;
      });
  }

  deleteUser(id_user: String) {
    Swal.fire({
      title: "Desea eliminar el usuario",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      confirmButtonColor: "#238276",
      denyButtonText: `Don't save`,
      cancelButtonColor: "#d33",
    }).then((response) => {
      if (response.isConfirmed) {
        Axios()
          .delete("eliminar_usuario/" + id_user)
          .then((response) => {
            if (response.data.status == "success") {
              Swal.fire({
                icon: "success",
                title: "Usuario Eliminado correctamente!",
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "No se pudo eliminar",
                showConfirmButton: false,
                timer: 1200,
              });
            }
          })
          .catch(() => {
            Swal.fire(
              "ERROR!",
              "Se ha presentado un error en el servidor!",
              "error"
            );
          });
      }
    });
  }
}
