






















































































































































































































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";
import loading from "@/components/animations/loading.vue";

@Component({
  components: {
    loading,
  },
})
export default class registerUser extends Vue {
  list_profiles = [];
  loadingCompo = false;

  data_user = {
    usernames: "",
    user_last_names: "",
    identification: "",
    email: "",
    phone_number: "",
    password: "",
    profile_id: "",
    user_nick: "",
  };

  mounted() {
    this.getProfiles();
  }

  async getProfiles() {
    Axios()
      .get("listar_perfiles")
      .then((response) => {
        if (response.data.status == "success") {
          this.list_profiles = response.data.results;
        } else {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Ha ocurrido un error al intentar obtener la información de perfiles",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#008000",
          });
        }
      });
  }

  async registerUser() {
    this.loadingCompo = true;
    Axios()
      .post("almacenar_usuario", this.data_user)
      .then((response) => {
        if (response.data.status == "success") {
          Swal.fire({
            title: "Usuario registrado correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            timer: 2000,
          });
          this.data_user.usernames = "";
          this.data_user.user_last_names = "";
          this.data_user.identification = "";
          this.data_user.email = "";
          this.data_user.phone_number = "";
          this.data_user.password = "";
          this.data_user.profile_id = "";
          this.data_user.user_nick = "";

          this.loadingCompo = false;
        } else {
          this.loadingCompo = false;
          Swal.fire({
            title: "Usuario no registrado",
            icon: "error",
            // text: response.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#FF0000",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "ERROR!",
          icon: "error",
          text: "Se ha presentado un error en el servidor!",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#FF0000",
        });
        this.loadingCompo = false;
      })
      .finally(() => {
        this.loadingCompo = false;
      });

    // {"status":"error",
    // "action":"stop",
    // "show":true,
    // "message":{
    //   "identification":["The identification has already been taken."]},
    //   "delay":null,
    //   "code":"ERR-00",
    //   "results":null}
  }
}
