































import { Component, Vue } from "vue-property-decorator";
import moduleWater from "@/components/manage_reports/module_water/index.vue";
@Component({
  components: {
    moduleWater,
  },
})
export default class index extends Vue {
  compWater = true;

  mounted() {}
}
