var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('aside',{staticClass:"\n      sidenav\n      navbar navbar-vertical navbar-expand-xs navbar-collapse\n      border-0\n      fixed-start\n      fndcolor\n    ",attrs:{"id":"side"}},[_vm._m(1),_c('div',{staticClass:"horizontal light mt-0 mb-2 my-3",staticStyle:{"height":"2px","background-color":"#00aaff"}}),_c('div',{staticClass:"w-auto max-height-vh-100"},[_c('ul',{staticClass:"navbar-nav my-4"},[_c('li',[_c('a',{staticClass:"nav-link text-white nav-itemm",on:{"click":function($event){(_vm.reportsManage = true),
                (_vm.userRegister = false),
                (_vm.usersManage = false),
                (_vm.usuariosClick = false),
                (_vm.reportesClick = !_vm.reportesClick)}}},[_c('div',{staticClass:"\n                text-white text-center\n                me-2\n                d-flex\n                align-items-center\n                justify-content-center\n              "},[_c('fa',{attrs:{"icon":"chart-line"}})],1),_c('span',{staticClass:"pointer nav-link-text"},[_vm._v("Reportes")])]),(_vm.reportesClick)?_c('div'):_vm._e()]),_c('li',[_c('a',{staticClass:"pointer nav-link text-white nav-itemm",on:{"click":function($event){_vm.usuariosClick = !_vm.usuariosClick}}},[_c('span',{staticClass:"nav-link-text nav-itemm"},[_vm._v("Gestionar Usuarios")])]),(_vm.usuariosClick)?_c('div',[_c('ul',{staticClass:"navbar-nav ms-2"},[_c('li',[_c('a',{staticClass:"pointer nav-link text-white nav-itemm",on:{"click":function($event){(_vm.userRegister = true),
                      (_vm.usersManage = false),
                      (_vm.reportsManage = false)}}},[_c('div',{staticClass:"\n                      text-white text-center\n                      me-2\n                      align-items-center\n                      justify-content-center\n                    "},[_c('fa',{attrs:{"icon":"add"}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Registrar Usuario")])])]),_c('li',[_c('a',{staticClass:"pointer nav-link text-white nav-itemm",on:{"click":function($event){(_vm.usersManage = true),
                      ((_vm.userRegister = false), (_vm.reportsManage = false))}}},[_c('div',{staticClass:"\n                      text-white text-center\n                      me-2\n                      d-flex\n                      align-items-center\n                      justify-content-center\n                    "},[_c('fa',{attrs:{"icon":"users"}})],1),_c('span',{staticClass:"nav-link-text"},[_vm._v("Usuarios")])])])])]):_vm._e()]),_c('li',{staticClass:"my-8"},[_c('div',{staticClass:"nav-link text-white nav-itemm-close"},[_c('router-link',{staticClass:"nav-link text-body px-0",attrs:{"to":"/"}},[_c('span',{staticClass:"d-sm-inline text-white"},[_vm._v("Cerrar Sesión")]),_c('fa',{staticClass:"ms-2 text-white lead moreSizeIcon",attrs:{"icon":"sign-out-alt"}})],1)],1)])])])]),_c('div',[_c('div',{staticClass:"conetnt",attrs:{"id":"contenido"}},[(_vm.usersManage)?_c('div',[_c('users',{staticClass:"mx-0"})],1):_vm._e(),(_vm.userRegister)?_c('div',[_c('register-userrr',{staticClass:"mx-0"})],1):_vm._e(),(_vm.reportsManage)?_c('div',[_c('reports',{staticClass:"mx-0"})],1):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iconNavbarSidenav",attrs:{"id":"iconNavbarSidenav"}},[_c('span',{staticClass:"espann"},[_vm._v("☰")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidenav-header text-center"},[_c('div',[_c('img',{staticClass:"my-2",staticStyle:{"width":"90px","height":"90px","object-fit":"cover"},attrs:{"src":require("../assets/logoApp 2.png"),"alt":""}})]),_c('a',{staticClass:"my-3"},[_c('span',{staticClass:"text-white h6 p-2"},[_vm._v("Construyendo Pacifico")])])])}]

export { render, staticRenderFns }