

























































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import loading from "@/components/animations/loading.vue";
import Swal from "sweetalert2";

@Component({
  components: {
    loading,
  },
})
export default class login extends Vue {
  user_nick = "";
  password = "";
  loadingCompo = false;

  login() {
    this.loadingCompo = true;   
    Axios()
      .post("iniciar_sesion", {
        user_nick: this.user_nick,
        password: this.password,
      })
      .then((response) => {
        if (response.data.status == "success") {
          this.$router.push({ name: "dashboard" });
          this.loadingCompo = false;
        } else {
          this.loadingCompo = false;
          Swal.fire({
            title: "Error Inicio Sesión",
            icon: "error",
            text: "Usuario o contraseña incorrectos",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#FF0000",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "ERROR!",
          icon: "error",
          text: "Se ha presentado un error en el servidor!",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#FF0000",
        });
      })
      .finally(() => {
        this.loadingCompo = false;
      });
  }
}
