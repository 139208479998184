import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import "bootstrap/dist/css/bootstrap.css"
import '@/css/material-dashboard.css?v=3.0.0'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

Vue.config.productionTip = true
Vue.component('fa',FontAwesomeIcon)
library.add(fas)

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
