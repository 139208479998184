






import Vue from "vue";
import login from "@/components/login.vue"; // @ is an alias to /src

export default Vue.extend({
  name: "Home",
  components: {
    login,
  },
});
