import Vue from 'vue'
import Router from "vue-router"
import Home from '../views/Home.vue'
import Dashboard /* webpackChunkName: "dashboard" */ from '../views/dashboard.vue'

Vue.use(Router)

const router = new Router({
  // mode: "history",
  // linkExactActiveClass: "active",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/',
      redirect: "dashboard",
      component: Dashboard,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard.vue')
        },
        {
          path: '/reportes',
          name: 'reportes',
          component: () => import(/* webpackChunkName: "reportes" */ '../components/manage_reports/index.vue')
        },
        {
          path: '/registrarUsuario',
          name: 'registrarUsuario',
          component: () => import(/* webpackChunkName: "RegisterUser" */ '../components/manage_users/registerUser.vue')
        },
        {
          path: '/gestionUsuarios',
          name: 'gestionUsuarios',
          component: () => import(/* webpackChunkName: "manageUsers" */ '../components/manage_users/index.vue')
        },                
      ]
    },

  ]
})



export default router
