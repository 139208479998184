



























































































































































import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import Axios from "@/Axios";
import Swal from "sweetalert2";

@Component({
  components: {},
  props: {
    idUserProp: Object,
  },
})
export default class modifyUser extends Vue {
  data_users = {
    id_user: null,
    usernames: "",
    user_last_names: "",
    identification: "",
    phone_number: "",
    email: "",
    profile_id: null,
    user_nick: "",
  };
  modifyDataUser() {
    Axios()
      .put(
        "modificar_usuario/" + this.$props.idUserProp.id_user,
        this.$props.idUserProp
      )
      .then((response) => {
        if (response.data.status == "success") {
          Swal.fire({
            title: "Usuario modificado correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
            timer: 2000,
          });
          location.reload();
        } else {
          Swal.fire({
            title: "Usuario no modificado",
            icon: "error",
            // text: response.data.message,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#FF0000",
          });
        }
      });
  }
}
